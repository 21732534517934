<template>
  <a-layout class="touchless-home">
    <a-layout-header>
      <div class="touchless-home-header">
        <div class="header-content">
          <div class="header-logo">
            <img src="../../public/img/logo-jxb.png" alt="Logo" />
          </div>
        </div>
      </div>
    </a-layout-header>
    <slot />
    <a-layout-footer>
      <h5>© STICKEARN 2021. All Rights Reserved</h5>
    </a-layout-footer>
  </a-layout>
</template>

<script>
export default {
  name: "AppLayoutHome"
};
</script>

<style lang="scss">
.touchless-home {
  &-header {
    background: #fc351c;
    padding: 8px 16px;

    @media only screen and (min-width: 768px) {
      padding: 8px 0;
    }
    .header-content {
      max-width: 768px;
      margin: 0 auto;
    }
    .header-logo {
      width: 150px;
      height: 150px;
      border-radius: 80px;
      border: 2px solid #fff;
      background: rgba($color: #000000, $alpha: .5);
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
